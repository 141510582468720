import type { MetaFunction } from '@remix-run/react'
import { CANONICAL_HOSTNAME, SITE_TITLE } from '~/config'

const defaultTitle = { title: SITE_TITLE }
const base = [
  { name: 'charset', content: 'utf-8' },
  { name: 'viewport', content: 'width=device-width,initial-scale=1' },
  {
    name: 'theme-color',
    content: 'hsl(342deg 100% 67%)',
    media: '(prefers-color-scheme: light)',
  },
  {
    name: 'theme-color',
    content: 'hsl(340deg 95% 55%)',
    media: '(prefers-color-scheme: dark)',
  },
]

export const meta: MetaFunction = ({ data: castData }) => {
  if (!castData) return [defaultTitle, ...base]

  const data = castData as Record<string, unknown>
  const authors =
    'authors' in data ? (data.authors as undefined | string[]) : []
  const banner = (data.banner as undefined | Record<string, unknown>) ?? {
    src: '/images/unfurl-image-2025.png',
  }

  const pageTitle = data.title ? `${data.title} - ${SITE_TITLE}` : SITE_TITLE
  const title = data.sectionTitle
    ? `${data.sectionTitle}: ${pageTitle}`
    : pageTitle
  const description = data?.description || data?.subtitle
  let image = banner.src

  /**
   * If image is locally hosted pre-pend the canonical hostname
   */
  if (banner && typeof banner.src === 'string' && !banner.src.includes('//'))
    image = `https://${CANONICAL_HOSTNAME}${image}`

  return [
    ...base,
    { title },
    { name: 'description', content: description },
    { name: 'keywords', content: data?.keywords },
    { name: 'author', content: authors?.join(', ') },

    { property: 'og:title', content: title },
    { property: 'og:image', content: image },
    { property: 'og:description', content: description },

    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:site', content: '@exploreomni' },
    { property: 'twitter:title', content: title },
    { property: 'twitter:description', content: description },
    { property: 'twitter:image', content: image },
  ]
}
